import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import React from 'react';

const SUGGESTIONS = [
  "Kira sözleşmesi yenileme şartları nelerdir?",
  "İşçinin sözleşmesinin işveren tarafından haksız feshedilmesi halinde tazminat hakkı olur mu?",
  "Anlaşmalı boşanma için hangi şartlar gereklidir?",
  "Yurtdışında yaşayanlar için askerlik süreci nasıl olur?"
];

// Circular Progress Component
const CircularProgress: React.FC = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '3rem 0'
    }}>
      <div style={{
        width: '60px',
        height: '60px',
        border: '4px solid rgba(255, 214, 19, 0.1)',
        borderTop: '4px solid #FFD613',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
        marginBottom: '1.5rem'
      }} />
      <style dangerouslySetInnerHTML={{
        __html: `
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `
      }} />
      <p style={{
        color: '#FFFFFF',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1rem',
        textAlign: 'center'
      }}>
        Yanıt hazırlanıyor...
      </p>
      <p style={{
        color: 'rgba(255, 255, 255, 0.6)',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '0.85rem',
        textAlign: 'center',
        maxWidth: '400px',
        margin: '0.5rem auto 0'
      }}>
        Hukuki analiz yapılıyor, bu işlem birkaç saniye sürebilir.
      </p>
    </div>
  );
};

interface APIResponse {
  task_id: string;
  status: string;
  result: {
    result: string;
    evaluations: Array<{
      kanun_adi: string;
      evaluation: string;
      articles: Array<{
        madde: string;
        icerik: string;
        konu: string[];
      }>;
    }>;
    processing_time: number;
  };
  processing_time: number;
}

// Modal Component for displaying full article content
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  kanunAdi: string;
  madde: string;
  icerik: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, kanunAdi, madde, icerik }) => {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <div style={{
        backgroundColor: 'rgba(30, 30, 30, 0.95)',
        borderRadius: '12px',
        padding: '2rem',
        maxWidth: '800px',
        width: '90%',
        maxHeight: '80vh',
        overflowY: 'auto',
        border: '1px solid rgba(255, 214, 19, 0.3)',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
        position: 'relative'
      }}>
        <button 
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            background: 'none',
            border: 'none',
            color: '#FFD613',
            fontSize: '1.5rem',
            cursor: 'pointer',
            padding: '0.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            transition: 'background-color 0.2s'
          }}
        >
          ✕
        </button>
        
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1.5rem',
          borderBottom: '1px solid rgba(255, 214, 19, 0.2)',
          paddingBottom: '1rem'
        }}>
          <h2 style={{
            color: '#FFD613',
            margin: 0,
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '600',
            fontSize: '1.4rem'
          }}>{kanunAdi}</h2>
          <span style={{
            background: 'rgba(255, 214, 19, 0.2)',
            padding: '6px 12px',
            borderRadius: '20px',
            fontSize: '0.9rem',
            color: '#FFD613',
            fontWeight: '500'
          }}>{madde}</span>
        </div>
        
        <div style={{
          color: '#FFFFFF',
          lineHeight: '1.7',
          fontFamily: 'Poppins, sans-serif',
          fontSize: '1rem',
          whiteSpace: 'pre-line'
        }}>
          {icerik}
        </div>
      </div>
    </div>
  );
};

// Law Article Card Component
interface LawArticleCardProps {
  kanunAdi: string;
  madde: string;
  icerik: string;
}

const LawArticleCard: React.FC<LawArticleCardProps> = ({ kanunAdi, madde, icerik }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  // Limit content to 100 characters instead of first two lines
  const truncatedContent = icerik.length > 100 
    ? icerik.substring(0, 100) + '...' 
    : icerik;
  
  return (
    <>
      <div 
        style={{
          background: 'rgba(255, 255, 255, 0.08)',
          borderRadius: '12px',
          padding: '1.5rem',
          marginBottom: '1rem',
          border: '1px solid rgba(255, 214, 19, 0.2)',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          cursor: 'pointer',
          transition: 'transform 0.2s, box-shadow 0.2s'
        }}
        onClick={() => setIsModalOpen(true)}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '0.75rem'
        }}>
          <h3 style={{
            color: '#FFD613',
            margin: 0,
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '600',
            fontSize: '1.1rem'
          }}>{kanunAdi}</h3>
          <span style={{
            background: 'rgba(255, 214, 19, 0.2)',
            padding: '4px 10px',
            borderRadius: '20px',
            fontSize: '0.85rem',
            color: '#FFD613',
            fontWeight: '500'
          }}>{madde}</span>
        </div>
        <p style={{
          color: '#FFFFFF',
          margin: '0 0 0.5rem 0',
          lineHeight: '1.5',
          fontFamily: 'Poppins, sans-serif',
          fontSize: '0.95rem',
          minHeight: '3em', // Ensure consistent height for cards
          maxHeight: '6em',  // Limit height to about 4 lines
          overflow: 'hidden'
        }}>{truncatedContent}</p>
        <div style={{
          textAlign: 'right',
          marginTop: '0.5rem'
        }}>
          <span style={{
            color: '#FFD613',
            fontSize: '0.85rem',
            fontFamily: 'Poppins, sans-serif',
            display: 'inline-flex',
            alignItems: 'center',
            gap: '0.25rem'
          }}>
            Detaylar <span style={{ fontSize: '0.7rem' }}>▶</span>
          </span>
        </div>
      </div>
      
      <Modal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        kanunAdi={kanunAdi}
        madde={madde}
        icerik={icerik}
      />
    </>
  );
};

const AIChat = () => {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [lawArticles, setLawArticles] = useState<Array<{
    kanunAdi: string;
    madde: string;
    icerik: string;
  }>>([]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!query.trim()) return;

    setIsLoading(true);
    setResponse('');
    setLawArticles([]);

    try {
      const response = await fetch('https://api.hukukarama.com/query', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: query
        })
      });

      if (!response.ok) {
        throw new Error('API yanıt vermedi');
      }

      const data: APIResponse = await response.json();
      
      console.log('Raw API Response:', data);
      
      if (data.status !== 'completed') {
        throw new Error('API işlemi tamamlanmadı');
      }

      // Set the main response text
      setResponse(data.result.result);

      // Extract law articles for card display
      const articles: Array<{
        kanunAdi: string;
        madde: string;
        icerik: string;
      }> = [];

      // Add evaluations and articles if available
      if (data.result.evaluations && data.result.evaluations.length > 0) {
        data.result.evaluations.forEach((evaluation) => {
          // Add articles if available
          if (evaluation.articles && evaluation.articles.length > 0) {
            evaluation.articles.forEach((article) => {
              articles.push({
                kanunAdi: evaluation.kanun_adi,
                madde: article.madde,
                icerik: article.icerik
              });
            });
          }
        });
      }

      setLawArticles(articles);
    } catch (error) {
      console.error('API Error:', error);
      setResponse("Üzgünüm, bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setQuery(suggestion);
  };

  return (
    <div className="chat-container" style={{
      padding: '120px 2rem',
      maxWidth: '1200px',
      margin: '0 auto'
    }}>
      {/* Header */}
      <div className="petition-header">
        <h1>Analiz AI- Olay/Durum Analiz Asistanı</h1>
        <p>Hukuki olay ya da durumunuzu yapay zeka destekli asistanımıza yazın, Analiz AI sizin için ilgili kanunlar çerçevesinde anında analiz etsin. Durumunuzla ilgili önfikir edinin. Yapay zekamız şimdilik şu kanunlar çerçevesinde analiz yapmaktadır: 
          <ul style={{
            listStyleType: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.5rem',
            justifyContent: 'center',
            margin: '1rem 0'
          }}>
            <li style={{ 
              padding: '8px 16px', 
              background: 'rgba(255, 214, 19, 0.1)', 
              borderRadius: '20px',
              border: '1px solid rgba(255, 214, 19, 0.2)'
            }}>Askeralma Kanunu</li>
            <li style={{ 
              padding: '8px 16px', 
              background: 'rgba(255, 214, 19, 0.1)', 
              borderRadius: '20px',
              border: '1px solid rgba(255, 214, 19, 0.2)'
            }}>İcra ve İflas Kanunu</li>
            <li style={{ 
              padding: '8px 16px', 
              background: 'rgba(255, 214, 19, 0.1)', 
              borderRadius: '20px',
              border: '1px solid rgba(255, 214, 19, 0.2)'
            }}>İdari Yargılama Usulü Kanunu</li>
            <li style={{ 
              padding: '8px 16px', 
              background: 'rgba(255, 214, 19, 0.1)', 
              borderRadius: '20px',
              border: '1px solid rgba(255, 214, 19, 0.2)'
            }}>İş Kanunu</li>
            <li style={{ 
              padding: '8px 16px', 
              background: 'rgba(255, 214, 19, 0.1)', 
              borderRadius: '20px',
              border: '1px solid rgba(255, 214, 19, 0.2)'
            }}>Kamu Görevlileri Sendikaları ve Toplu Sözleşme Kanunu</li>
            <li style={{ 
              padding: '8px 16px', 
              background: 'rgba(255, 214, 19, 0.1)', 
              borderRadius: '20px',
              border: '1px solid rgba(255, 214, 19, 0.2)'
            }}>Sendikalar ve Toplu İş Sözleşmeleri Kanunu</li>
            <li style={{ 
              padding: '8px 16px', 
              background: 'rgba(255, 214, 19, 0.1)', 
              borderRadius: '20px',
              border: '1px solid rgba(255, 214, 19, 0.2)'
            }}>Türk Borçlar Kanunu</li>
            <li style={{ 
              padding: '8px 16px', 
              background: 'rgba(255, 214, 19, 0.1)', 
              borderRadius: '20px',
              border: '1px solid rgba(255, 214, 19, 0.2)'
            }}>Türk Ceza Kanunu</li>
            <li style={{ 
              padding: '8px 16px', 
              background: 'rgba(255, 214, 19, 0.1)', 
              borderRadius: '20px',
              border: '1px solid rgba(255, 214, 19, 0.2)'
            }}>Türk Medeni Kanunu</li>
            <li style={{ 
              padding: '8px 16px', 
              background: 'rgba(255, 214, 19, 0.1)', 
              borderRadius: '20px',
              border: '1px solid rgba(255, 214, 19, 0.2)'
            }}>Türk Ticaret Kanunu</li>
          </ul></p>
          <p style={{ textAlign: 'center', fontStyle: 'italic', marginTop: '0.5rem' }}>
            Diğer Kanunlar ve Mevzuatlar yakın zamanda eklenecektir.
          </p>
      </div>

     

      {/* Suggestions */}
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem',
        justifyContent: 'center',
        marginBottom: '2rem'
      }}>
        {SUGGESTIONS.map((suggestion, index) => (
          <button
            key={index}
            onClick={() => handleSuggestionClick(suggestion)}
            style={{
              padding: '12px 24px',
              background: 'none',
              border: '1px solid rgba(255, 255, 255, 0.2)',
              borderRadius: '25px',
              fontFamily: 'Poppins, sans-serif',
              fontSize: '16px',
              color: '#FFFFFF',
              cursor: 'pointer',
              transition: 'all 0.3s ease'
            }}
          >
            {suggestion}
          </button>
        ))}
      </div>

      {/* Chat Form */}
      <form onSubmit={handleSubmit} style={{
        marginBottom: '3rem'
      }}>
        <div style={{
          display: 'flex',
          gap: '1rem',
          maxWidth: '800px',
          margin: '0 auto'
        }}>
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Hukuki sorunuzu yazın..."
            style={{
              flex: 1,
              padding: '1rem 1.5rem',
              borderRadius: '30px',
              border: '1px solid rgba(255, 214, 19, 0.3)',
              background: 'rgba(255, 255, 255, 0.05)',
              color: '#FFFFFF',
              fontFamily: 'Poppins, sans-serif',
              fontSize: '16px'
            }}
          />
          <button
            type="submit"
            disabled={isLoading}
            style={{
              padding: '1rem 2rem',
              borderRadius: '30px',
              border: 'none',
              background: 'linear-gradient(100.61deg, #FFD613 17.65%, #8E780D 57.89%)',
              color: '#000000',
              fontFamily: 'Poppins, sans-serif',
              fontSize: '16px',
              fontWeight: '500',
              cursor: 'pointer',
              transition: 'all 0.3s ease'
            }}
          >
            {isLoading ? 'Yanıt Hazırlanıyor...' : 'Gönder'}
          </button>
        </div>
      </form>

      <div className="disclaimer-note" style={{
        backgroundColor: "rgba(255, 214, 19, 0.1)",
        border: "1px solid rgba(255, 214, 19, 0.3)",
        borderRadius: "8px",
        padding: "12px 16px",
        marginBottom: "2rem",
        fontSize: "14px",
        color: "#FFFFFF",
        textAlign: "left",
        lineHeight: "1.6",
        maxWidth: "800px",
        margin: "0 auto 3rem auto",
        fontFamily: "Poppins, sans-serif"
      }}>
        <strong style={{ color: "#FFD613", fontFamily: "Poppins, sans-serif", fontWeight: "500" }}>Önemli Not:</strong> Hukuki Asistan tamamen yapay zeka tarafından desteklenmektedir. Yapay zeka hata yapabilir, yanlış bilgiler sunabilir veya güncel olmayan hukuki bilgiler içerebilir. Verilen yanıtların hukuki sorumluluğu tamamen kullanıcıya aittir. Önemli hukuki konularda bir avukata danışmanız önerilir.
      </div>

      {/* Loading Indicator */}
      {isLoading && (
        <div style={{
          background: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '20px',
          padding: '1rem',
          marginTop: '2rem',
          maxWidth: '1000px',
          margin: '0 auto',
          border: '1px solid rgba(255, 255, 255, 0.1)'
        }}>
          <CircularProgress />
        </div>
      )}

      {/* Response */}
      {response && (
        <div style={{
          background: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '20px',
          padding: '2rem',
          marginTop: '2rem',
          maxWidth: '1000px',
          margin: '0 auto',
          border: '1px solid rgba(255, 255, 255, 0.1)'
        }}>
          {/* Main Response */}
          <div style={{ marginBottom: '2rem', textAlign: 'left' }}>
            <ReactMarkdown 
              remarkPlugins={[remarkGfm]}
              components={{
                h1: ({node, ...props}) => <h1 style={{color: '#FFD613', marginTop: '2rem', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif', fontWeight: '600', textAlign: 'left'}} {...props} />,
                h2: ({node, ...props}) => <h2 style={{color: '#FFD613', marginTop: '2rem', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif', fontWeight: '600', textAlign: 'left'}} {...props} />,
                h3: ({node, ...props}) => <h3 style={{color: '#FFD613', marginTop: '1.5rem', marginBottom: '0.75rem', fontFamily: 'Poppins, sans-serif', fontWeight: '600', textAlign: 'left'}} {...props} />,
                h4: ({node, ...props}) => <h4 style={{color: '#FFD613', marginTop: '1.25rem', marginBottom: '0.5rem', fontFamily: 'Poppins, sans-serif', fontWeight: '600', textAlign: 'left'}} {...props} />,
                h5: ({node, ...props}) => <h5 style={{color: '#FFD613', marginTop: '1rem', marginBottom: '0.5rem', fontFamily: 'Poppins, sans-serif', fontWeight: '600', textAlign: 'left'}} {...props} />,
                p: ({node, children, ...props}) => {
                  // Check if children contains block elements (unlikely with markdown but possible)
                  const hasBlockElements = React.Children.toArray(children).some(
                    child => typeof child === 'object' && 
                            (child as React.ReactElement)?.type === 'div' || 
                            (child as React.ReactElement)?.type === 'ul' || 
                            (child as React.ReactElement)?.type === 'ol'
                  );
                  
                  // If there are block elements, render without wrapping p
                  if (hasBlockElements) {
                    return <>{children}</>;
                  }
                  
                  // Otherwise render normal paragraph
                  return <p style={{color: '#FFFFFF', marginBottom: '1rem', lineHeight: '1.6', fontFamily: 'Poppins, sans-serif', textAlign: 'left'}} {...props}>{children}</p>;
                },
                ul: ({node, ...props}) => <ul style={{color: '#FFFFFF', marginBottom: '1rem', paddingLeft: '1.5rem', fontFamily: 'Poppins, sans-serif', textAlign: 'left'}} {...props} />,
                ol: ({node, ...props}) => <ol style={{color: '#FFFFFF', marginBottom: '1rem', paddingLeft: '1.5rem', fontFamily: 'Poppins, sans-serif', textAlign: 'left'}} {...props} />,
                li: ({node, ...props}) => <li style={{color: '#FFFFFF', marginBottom: '0.5rem', fontFamily: 'Poppins, sans-serif', textAlign: 'left'}} {...props} />,
                em: ({node, ...props}) => <em style={{color: '#FFD613', fontStyle: 'normal', fontFamily: 'Poppins, sans-serif'}} {...props} />,
                strong: ({node, ...props}) => <strong style={{color: '#FFD613', fontFamily: 'Poppins, sans-serif'}} {...props} />,
              }}
            >
              {response}
            </ReactMarkdown>
          </div>

          {/* Display law articles if available */}
          {lawArticles.length > 0 && (
            <div>
              <h2 style={{
                color: '#FFD613',
                marginTop: '2rem',
                marginBottom: '1.5rem',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: '600',
                textAlign: 'center'
              }}>İlgili Kanun Maddeleri</h2>
              
            
              
              <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                gap: '1.5rem'
              }}>
                {lawArticles.map((article, index) => (
                  <LawArticleCard
                    key={index}
                    kanunAdi={article.kanunAdi}
                    madde={article.madde}
                    icerik={article.icerik}
                  />
                ))}
              </div>
              
            
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AIChat; 