// import React from 'react';

const Terms = () => {
  return (
    <section className="terms-page">
      <div className="petition-header">
        <h1>Kullanım Koşulları ve Gizlilik Sözleşmesi</h1>
        <p>Bu sayfada, platformumuzun kullanım koşulları ve gizlilik politikası hakkında bilgi edinebilirsiniz.</p>
        
      
      </div>
      
      <div className="terms-container">
        <div className="terms-section">
          <h2>1. Kullanım Koşulları</h2>
          
          <h3>1.1. Genel Hükümler</h3>
          <p>
            LysMind tarafından sunulan hukuk platformuna ("Platform") erişiminiz ve kullanımınız aşağıdaki kullanım koşullarına tabidir. 
            Bu platformu kullanarak, bu koşulları kabul etmiş sayılırsınız. Bu koşulları kabul etmiyorsanız, lütfen platformu kullanmayınız.
          </p>
          
          <h3>1.2. Sorumluluk Reddi</h3>
          <p>
            <strong>Platform üzerinden sunulan tüm hizmetler ve bilgiler sadece bilgilendirme amaçlıdır ve hiçbir şekilde hukuki tavsiye niteliği taşımamaktadır. 
            Platformda yer alan bilgiler ve yapay zeka analizleri, profesyonel hukuki danışmanlık hizmetinin yerini tutmaz.</strong>
          </p>
          <p>
            <strong>Platformu kullanarak alacağınız tüm kararlar ve gerçekleştireceğiniz tüm eylemler tamamen kendi sorumluluğunuzdadır. 
            LysMind, platform üzerinden edinilen bilgilere dayanarak alınan kararlar veya gerçekleştirilen eylemler neticesinde doğabilecek 
            hiçbir doğrudan veya dolaylı zarardan sorumlu tutulamaz.</strong>
          </p>
          
          <h3>1.3. Yapay Zeka Kullanımı</h3>
          <p>
            Platform, yapay zeka teknolojileri kullanmaktadır. Bu teknolojiler her ne kadar gelişmiş olsa da, 
            tam doğruluk veya eksiksizlik garantisi verilemez. Yapay zeka tarafından üretilen içerikler, analizler ve dilekçeler 
            her zaman bir hukukçu tarafından kontrol edilmelidir.
          </p>
          
          <h3>1.4. İçerik Doğruluğu</h3>
          <p>
            Platform içeriğinin güncel ve doğru olması için çaba gösterilmekle birlikte, içeriklerin eksiksiz, doğru veya güncel olduğu 
            garanti edilmemektedir. Platform içeriğinde yer alan bilgilerdeki hatalar veya eksiklikler nedeniyle yaşanabilecek zararlardan 
            LysMind sorumlu tutulamaz.
          </p>
          
          <h3>1.5. Kullanıcı Sorumluluğu</h3>
          <p>
            Kullanıcılar, Platformu yalnızca yasal amaçlar doğrultusunda kullanmakla yükümlüdür. Platform aracılığıyla yasadışı faaliyetlerde 
            bulunmak, başkalarının haklarını ihlal etmek veya platformun normal işleyişini engelleyecek davranışlarda bulunmak kesinlikle yasaktır.
          </p>
          
          <h3>1.6. Fikri Mülkiyet</h3>
          <p>
            Platform üzerindeki tüm içerikler, markalar, logolar, yazılımlar ve diğer materyaller LysMind'a veya ilgili lisans sahiplerine aittir. 
            Bu içeriklerin izinsiz kullanımı, kopyalanması veya dağıtılması yasaktır.
          </p>
        </div>
        
        <div className="terms-section">
          <h2>2. Gizlilik Politikası</h2>
          
          <h3>2.1. Toplanan Bilgiler</h3>
          <p>
            Platform, kullanıcıların hukuki analizler gerçekleştirebilmesi ve dilekçe oluşturabilmesi için bazı kişisel bilgiler talep edebilir. 
            Bu bilgiler, kullanıcının açık rızasıyla toplanır ve sadece talep edilen hizmetlerin sağlanması amacıyla kullanılır.
          </p>
          
          <h3>2.2. Bilgilerin Kullanımı</h3>
          <p>
            Kullanıcılardan toplanan bilgiler:
          </p>
          <ul>
            <li>Talep edilen hizmetlerin sağlanması</li>
            <li>Platform kullanım deneyiminin iyileştirilmesi</li>
            <li>Gerekli olduğu takdirde kullanıcılarla iletişime geçilmesi</li>
            <li>Platformun güvenliğinin sağlanması</li>
            <li>Yasal yükümlülüklerin yerine getirilmesi</li>
          </ul>
          <p>
            amaçlarıyla kullanılabilir.
          </p>
          
          <h3>2.3. Bilgilerin Paylaşımı</h3>
          <p>
            Kullanıcılara ait kişisel bilgiler, aşağıdaki durumlar dışında üçüncü taraflarla paylaşılmaz:
          </p>
          <ul>
            <li>Yasal bir zorunluluk olması halinde (mahkeme kararı, yasal süreç vs.)</li>
            <li>Kullanıcının açık rızasının olması halinde</li>
            <li>Platformun hakları, mülkiyeti veya güvenliğini korumak için gerekli olması halinde</li>
          </ul>
          
          <h3>2.4. Veri Güvenliği</h3>
          <p>
            LysMind, kullanıcılara ait bilgilerin güvenliğini sağlamak için gerekli teknik ve idari önlemleri almaktadır. 
            Ancak, internet üzerinden gerçekleştirilen veri iletimlerinde mutlak güvenlik garantisi verilemez.
          </p>
          
          <h3>2.5. Çerezler (Cookies)</h3>
          <p>
            Platform, kullanıcı deneyimini iyileştirmek amacıyla çerezler kullanabilir. Kullanıcılar, tarayıcı ayarlarını değiştirerek 
            çerezleri reddedebilir veya çerez kullanımı hakkında bilgilendirilmeyi talep edebilir. Ancak, bu durumda Platformun bazı 
            özelliklerinden tam olarak yararlanamayabilirler.
          </p>
          
          <h3>2.6. Kullanıcı Hakları</h3>
          <p>
            Kullanıcılar, kişisel verileriyle ilgili olarak:
          </p>
          <ul>
            <li>Veri işleme faaliyeti hakkında bilgi talep etme</li>
            <li>Verilerine erişim ve düzeltme talep etme</li>
            <li>Verilerinin silinmesini talep etme</li>
            <li>Verilerinin işlenmesine itiraz etme</li>
          </ul>
          <p>
            haklarına sahiptir. Bu haklarınızı kullanmak için iletişim bilgilerimiz üzerinden bizimle iletişime geçebilirsiniz.
          </p>
        </div>
        
        <div className="terms-section">
          <h2>3. Son Hükümler</h2>
          
          <h3>3.1. Değişiklikler</h3>
          <p>
            LysMind, bu kullanım koşulları ve gizlilik politikasını herhangi bir zamanda değiştirme hakkını saklı tutar. 
            Değişiklikler, Platform üzerinde yayınlandığı tarihten itibaren geçerli olacaktır. Kullanıcıların, Platform'u 
            kullanmaya devam etmeleri, güncellenmiş koşulları kabul ettikleri anlamına gelir.
          </p>
          
          <h3>3.2. Geçerli Hukuk</h3>
          <p>
            Bu kullanım koşulları ve gizlilik politikası, Türkiye Cumhuriyeti kanunlarına tabidir ve bu kanunlara göre yorumlanır.
          </p>
          
          <h3>3.3. İletişim</h3>
          <p>
            Kullanım koşulları ve gizlilik politikasıyla ilgili sorularınız için hukukarama@lysmind.com adresinden 
            bizimle iletişime geçebilirsiniz.
          </p>
          
          <div className="terms-disclaimer">
            <p>
              <strong>
                Bu kullanım koşulları ve gizlilik politikası metni, son güncelleme tarihi olan {new Date().toLocaleDateString('tr-TR')} 
                itibariyle geçerlidir. Platform kullanımınız sırasında doğabilecek tüm sorumluluklar size aittir ve LysMind 
                hiçbir sorumluluk kabul etmez.
              </strong>
            </p>
          </div>
        </div>
        
       
        
        
      </div>
    </section>
  );
};

export default Terms; 